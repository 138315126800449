document.addEventListener('DOMContentLoaded', function() {
    // Create the overlay div
    var overlay = document.createElement('div');
    overlay.className = 'overlay';
    document.body.appendChild(overlay);

    // Get all popup wrappers for both flavors and recipes
    var flavorWrappers = document.querySelectorAll('.popup-wrapper');
    var recipeWrappers = document.querySelectorAll('.recipe-wrapper');
    var scrollPosition = 0;

    // Function to calculate scrollbar width
    function getScrollbarWidth() {
        return window.innerWidth - document.documentElement.clientWidth;
    }

    // Function to show the popup and overlay
    function showPopup(popup) {
        scrollPosition = window.pageYOffset;
        var scrollbarWidth = getScrollbarWidth();
        document.body.style.top = `-${scrollPosition}px`;
        document.body.style.position = 'fixed';
        document.body.style.width = `calc(100% - ${scrollbarWidth}px)`;
        overlay.style.display = 'flex';
        popup.style.display = 'flex';
        document.body.classList.add('overlay-active');
    }

    // Function to hide the popup and overlay
    function hidePopup() {
        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.width = '';
        window.scrollTo(0, scrollPosition);
        overlay.style.display = 'none';
        var popups = document.querySelectorAll('.popup-wrap, .recipe-popup');
        popups.forEach(function(popup) {
            popup.style.display = 'none';
        });
        document.body.classList.remove('overlay-active');
    }

    // Add click event listener to each flavor popup wrapper
    flavorWrappers.forEach(function(wrapper) {
        var openButton = wrapper.querySelector('.flavor-popup');
        var popup = wrapper.querySelector('.popup-wrap');
        var closeButton = wrapper.querySelector('.popup-flavor-close');

        openButton.addEventListener('click', function() {
            showPopup(popup);
        });

        // Prevent event propagation to avoid closing the popup when clicking inside it
        popup.addEventListener('click', function(event) {
            event.stopPropagation();
        });

        // Add click event listener to the close button
        closeButton.addEventListener('click', function() {
            hidePopup();
        });
    });

    // Add click event listener to each recipe popup wrapper
    recipeWrappers.forEach(function(wrapper) {
        var openButton = wrapper.querySelector('.recipe-wrap');
        var popup = wrapper.querySelector('.recipe-popup');
        var closeButton = wrapper.querySelector('.popup-recipe-close');

        openButton.addEventListener('click', function() {
            showPopup(popup);
        });

        // Prevent event propagation to avoid closing the popup when clicking inside it
        popup.addEventListener('click', function(event) {
            event.stopPropagation();
        });

        // Add click event listener to the close button
        closeButton.addEventListener('click', function() {
            hidePopup();
        });
    });

    // Add click event listener to the overlay to close the popup
    overlay.addEventListener('click', function(event) {
        hidePopup();
    });
});


//Age Gate
document.addEventListener('DOMContentLoaded', function() {
    // Create the overlay div
    var overlay = document.createElement('div');
    overlay.className = 'overlay';
    document.body.appendChild(overlay);

    // Get all popup wrappers for both flavors and recipes
    var flavorWrappers = document.querySelectorAll('.popup-wrapper');
    var recipeWrappers = document.querySelectorAll('.recipe-wrapper');
    var scrollPosition = 0;

    // Function to calculate scrollbar width
    function getScrollbarWidth() {
        return window.innerWidth - document.documentElement.clientWidth;
    }

    // Function to show the popup and overlay
    function showPopup(popup) {
        scrollPosition = window.pageYOffset;
        var scrollbarWidth = getScrollbarWidth();
        document.body.style.top = `-${scrollPosition}px`;
        document.body.style.position = 'fixed';
        document.body.style.width = `calc(100% - ${scrollbarWidth}px)`;
        overlay.style.display = 'flex';
        popup.style.display = 'flex';
        document.body.classList.add('overlay-active');
    }

    // Function to hide the popup and overlay
    function hidePopup() {
        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.width = '';
        window.scrollTo(0, scrollPosition);
        overlay.style.display = 'none';
        var popups = document.querySelectorAll('.popup-wrap, .recipe-popup');
        popups.forEach(function(popup) {
            popup.style.display = 'none';
        });
        document.body.classList.remove('overlay-active');
    }

    // Add click event listener to each flavor popup wrapper
    flavorWrappers.forEach(function(wrapper) {
        var openButton = wrapper.querySelector('.flavor-popup');
        var popup = wrapper.querySelector('.popup-wrap');
        var closeButton = wrapper.querySelector('.popup-flavor-close');

        openButton.addEventListener('click', function() {
            showPopup(popup);
        });

        // Prevent event propagation to avoid closing the popup when clicking inside it
        popup.addEventListener('click', function(event) {
            event.stopPropagation();
        });

        // Add click event listener to the close button
        closeButton.addEventListener('click', function() {
            hidePopup();
        });
    });

    // Add click event listener to each recipe popup wrapper
    recipeWrappers.forEach(function(wrapper) {
        var openButton = wrapper.querySelector('.recipe-wrap');
        var popup = wrapper.querySelector('.recipe-popup');
        var closeButton = wrapper.querySelector('.popup-recipe-close');

        openButton.addEventListener('click', function() {
            showPopup(popup);
        });

        // Prevent event propagation to avoid closing the popup when clicking inside it
        popup.addEventListener('click', function(event) {
            event.stopPropagation();
        });

        // Add click event listener to the close button
        closeButton.addEventListener('click', function() {
            hidePopup();
        });
    });

    // Add click event listener to the overlay to close the popup
    overlay.addEventListener('click', function(event) {
        hidePopup();
    });
});


document.addEventListener('DOMContentLoaded', function() {
    // Create the overlay div
    var overlay = document.createElement('div');
    overlay.className = 'overlay';
    document.body.appendChild(overlay);

    // Get all popup wrappers for both flavors and recipes
    var flavorWrappers = document.querySelectorAll('.popup-wrapper');
    var recipeWrappers = document.querySelectorAll('.recipe-wrapper');
    var scrollPosition = 0;

    // Function to calculate scrollbar width
    function getScrollbarWidth() {
        return window.innerWidth - document.documentElement.clientWidth;
    }

    // Function to show the popup and overlay
    function showPopup(popup) {
        scrollPosition = window.pageYOffset;
        var scrollbarWidth = getScrollbarWidth();
        document.body.style.top = `-${scrollPosition}px`;
        document.body.style.position = 'fixed';
        document.body.style.width = `calc(100% - ${scrollbarWidth}px)`;
        overlay.style.display = 'flex';
        popup.style.display = 'flex';
        document.body.classList.add('overlay-active');
    }

    // Function to hide the popup and overlay
    function hidePopup() {
        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.width = '';
        window.scrollTo(0, scrollPosition);
        overlay.style.display = 'none';
        var popups = document.querySelectorAll('.popup-wrap, .recipe-popup');
        popups.forEach(function(popup) {
            popup.style.display = 'none';
        });
        document.body.classList.remove('overlay-active');
    }

    // Add click event listener to each flavor popup wrapper
    flavorWrappers.forEach(function(wrapper) {
        var openButton = wrapper.querySelector('.flavor-popup');
        var popup = wrapper.querySelector('.popup-wrap');
        var closeButton = wrapper.querySelector('.popup-flavor-close');

        openButton.addEventListener('click', function() {
            showPopup(popup);
        });

        // Prevent event propagation to avoid closing the popup when clicking inside it
        popup.addEventListener('click', function(event) {
            event.stopPropagation();
        });

        // Add click event listener to the close button
        closeButton.addEventListener('click', function() {
            hidePopup();
        });
    });

    // Add click event listener to each recipe popup wrapper
    recipeWrappers.forEach(function(wrapper) {
        var openButton = wrapper.querySelector('.recipe-wrap');
        var popup = wrapper.querySelector('.recipe-popup');
        var closeButton = wrapper.querySelector('.popup-recipe-close');

        openButton.addEventListener('click', function() {
            showPopup(popup);
        });

        // Prevent event propagation to avoid closing the popup when clicking inside it
        popup.addEventListener('click', function(event) {
            event.stopPropagation();
        });

        // Add click event listener to the close button
        closeButton.addEventListener('click', function() {
            hidePopup();
        });
    });

    // Add click event listener to the overlay to close the popup
    overlay.addEventListener('click', function(event) {
        hidePopup();
    });
});


//Age Gate
document.addEventListener('DOMContentLoaded', function() {
    // Create the overlay div
    var overlay = document.createElement('div');
    overlay.className = 'overlay';
    document.body.appendChild(overlay);

    // Get all popup wrappers for both flavors and recipes
    var flavorWrappers = document.querySelectorAll('.popup-wrapper');
    var recipeWrappers = document.querySelectorAll('.recipe-wrapper');
    var scrollPosition = 0;

    // Function to calculate scrollbar width
    function getScrollbarWidth() {
        return window.innerWidth - document.documentElement.clientWidth;
    }

    // Function to show the popup and overlay
    function showPopup(popup) {
        scrollPosition = window.pageYOffset;
        var scrollbarWidth = getScrollbarWidth();
        document.body.style.top = `-${scrollPosition}px`;
        document.body.style.position = 'fixed';
        document.body.style.width = `calc(100% - ${scrollbarWidth}px)`;
        overlay.style.display = 'flex';
        popup.style.display = 'flex';
        document.body.classList.add('overlay-active');
    }

    // Function to hide the popup and overlay
    function hidePopup() {
        document.body.style.position = '';
        document.body.style.top = '';
        document.body.style.width = '';
        window.scrollTo(0, scrollPosition);
        overlay.style.display = 'none';
        var popups = document.querySelectorAll('.popup-wrap, .recipe-popup');
        popups.forEach(function(popup) {
            popup.style.display = 'none';
        });
        document.body.classList.remove('overlay-active');
    }

    // Add click event listener to each flavor popup wrapper
    flavorWrappers.forEach(function(wrapper) {
        var openButton = wrapper.querySelector('.flavor-popup');
        var popup = wrapper.querySelector('.popup-wrap');
        var closeButton = wrapper.querySelector('.popup-flavor-close');

        openButton.addEventListener('click', function() {
            showPopup(popup);
        });

        // Prevent event propagation to avoid closing the popup when clicking inside it
        popup.addEventListener('click', function(event) {
            event.stopPropagation();
        });

        // Add click event listener to the close button
        closeButton.addEventListener('click', function() {
            hidePopup();
        });
    });

    // Add click event listener to each recipe popup wrapper
    recipeWrappers.forEach(function(wrapper) {
        var openButton = wrapper.querySelector('.recipe-wrap');
        var popup = wrapper.querySelector('.recipe-popup');
        var closeButton = wrapper.querySelector('.popup-recipe-close');

        openButton.addEventListener('click', function() {
            showPopup(popup);
        });

        // Prevent event propagation to avoid closing the popup when clicking inside it
        popup.addEventListener('click', function(event) {
            event.stopPropagation();
        });

        // Add click event listener to the close button
        closeButton.addEventListener('click', function() {
            hidePopup();
        });
    });

    // Add click event listener to the overlay to close the popup
    overlay.addEventListener('click', function(event) {
        hidePopup();
    });
});


//Age Gate
document.addEventListener('DOMContentLoaded', function() {

    // --- 1. Override Form Submission ---
    function overrideFormSubmit() {
      var form = document.getElementById("gform_1");
      if (form) {
        if (form.hasAttribute("onsubmit")) {
          form.removeAttribute("onsubmit");
        }
        form.submit = function() {
          return false;
        };
        form.addEventListener("submit", function(e) {
          e.preventDefault();
          e.stopPropagation();
          e.stopImmediatePropagation();
          return false;
        }, true);
      }
    }
  
    // --- 2. Override Gravity Forms AJAX Submission (if applicable) ---
    setTimeout(function() {
      if (window.gform && typeof gform.doAjaxSubmit === "function") {
        gform.doAjaxSubmit = function(formId) {
          return false;
        };
      }
    }, 2000);
  
    // --- 3. Age Check Function ---
    function checkAge() {
      var monthEl = document.getElementById("input_1_1_1"),
          dayEl   = document.getElementById("input_1_1_2"),
          yearEl  = document.getElementById("input_1_1_3");
      if (!monthEl || !dayEl || !yearEl) {
        return false;
      }
      var month = parseInt(monthEl.value, 10),
          day   = parseInt(dayEl.value, 10),
          year  = parseInt(yearEl.value, 10);
      if (isNaN(month) || isNaN(day) || isNaN(year)) {
        return false;
      }
      var birthdate = new Date(year, month - 1, day),
          today = new Date(),
          age = today.getFullYear() - birthdate.getFullYear();
      var m = today.getMonth() - birthdate.getMonth();
      if (m < 0 || (m === 0 && today.getDate() < birthdate.getDate())) {
        age--;
      }
      return age >= 21;
    }
  
    // --- 4. Show Validation Message Popup Using Original HTML & Classes ---
    function showValidationMessage() {
      const validationMessage = "You must be 21 or older to enter this site.";
      let messageDiv = document.querySelector('.gf-age-validation');
      if (!messageDiv) {
        messageDiv = document.createElement('div');
        messageDiv.classList.add('gf-age-validation');
        messageDiv.style.position = 'absolute';
        const messageContent = document.createElement('div');
        messageContent.classList.add('message-content');
        messageContent.textContent = validationMessage;
        const closeButton = document.createElement('button');
        closeButton.textContent = 'X';
        closeButton.classList.add('close-button');
        closeButton.addEventListener('click', function() {
          messageDiv.style.display = 'none';
        });
        messageDiv.appendChild(messageContent);
        messageDiv.appendChild(closeButton);
        var gformWrapper = document.querySelector('#gform_wrapper_1');
        if (gformWrapper) {
          gformWrapper.appendChild(messageDiv);
        } else {
          document.body.appendChild(messageDiv);
        }
      } else {
        messageDiv.style.display = 'block';
      }
    }
  
    // --- 5. Attach Click Listener to the Submit Button ---
    function attachSubmitButtonListener() {
      var submitButton = document.getElementById("gform_submit_button_1");
      if (submitButton) {
        submitButton.setAttribute("type", "button");
        submitButton.removeAttribute("onclick");
        submitButton.addEventListener("click", function(e) {
          e.preventDefault();
          e.stopPropagation();
          e.stopImmediatePropagation();
          if (checkAge()) {
            sessionStorage.setItem("ageVerified", "true");
            var popup = document.querySelector(".age-gate");
            if (popup) {
              popup.style.display = "none";
            }
          } else {
            showValidationMessage();
          }
          return false;
        }, true);
      }
    }
  
    // --- 6. Check Session and Toggle the Popup ---
    function checkSession() {
      var popup = document.querySelector(".age-gate");
      if (popup) {
        if (sessionStorage.getItem("ageVerified") === "true") {
          popup.style.display = "none";
        } else {
          popup.style.display = "block";
        }
      }
    }
  
    // --- 7. Poll for the Popup Element and Initialize Everything ---
    var interval = setInterval(function() {
      var popup = document.querySelector(".age-gate");
      if (popup) {
        clearInterval(interval);
        overrideFormSubmit();
        attachSubmitButtonListener();
        checkSession();
      }
    }, 100);
  });
  
